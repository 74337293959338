import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';

import {
  CancelOrder as CancelOrderAction,
  orderReject as orderRejectAction,
} from './orderActions';

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: red,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  textColor: {
    color: '#ffffff',

    margin: 5,
  },
});

class CancelButton extends Component {
  handleCancelOrder = () => {
    const { CancelOrder, record } = this.props;
    CancelOrder(record.id, record);
  };

  handleReject = () => {
    const { orderReject, record } = this.props;
    orderReject(record.id, record);
  };

  render() {
    const { record } = this.props;
    const { classes } = this.props;
    return (
      <span>
        <MuiThemeProvider theme={theme}>
          <Button
            variant='contained'
            className={classes.textColor}
            color='secondary'
            style={{ width: '150px' }}
            onClick={this.handleCancelOrder}
          >
            Cancel
          </Button>
          {/* <Button
            variant="contained"
            className={classes.textColor}
            color="secondary"
            onClick={this.handleReject}
          >
            Reject
          </Button> */}
        </MuiThemeProvider>
      </span>
    );
  }
}

CancelButton.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
  CancelOrder: PropTypes.func,
  orderReject: PropTypes.func,
};

export default connect(
  null,
  {
    CancelOrder: CancelOrderAction,
    orderReject: orderRejectAction,
  },
)(withStyles(styles)(CancelButton));
