import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import SendTwoTone from '@material-ui/icons/SendTwoTone';
import SmsIcon from '@material-ui/icons/Sms';
import Reorder from '@material-ui/icons/Reorder';
import {
  translate,
  DashboardMenuItem,
  MenuItemLink,
  Responsive,
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import { HomeSliderIcon } from './homeSlider';
import { GalleryIcon } from './gallery';
import { GiftSettingIcon } from './giftSetting';
import { AreasIcon } from './areas';
import { BranchesIcon } from './branches';
import { PromoIcon } from './promoCodes';
import { ContactUsIcon } from './contactUs';
import { ProductsIcon } from './products';
import { UserPointsIcon } from './UserPoints';
import { RedeemsIcon } from './UserRedeems';
import { OrdersIcon } from './orders';
import { ProductCategoryIcon } from './productCategory';
import { ProductsAdditionIcon } from './productsAddition';
import { ProductsAdditionCategoryIcon } from './productsAdditionCategory';
import { UsersIcon } from './users';
import { NotificationsIcon } from './notifications';
import { CitiesIcon } from './cities';
import { TransactionsIcon } from './transactions';
import { withStyles } from '@material-ui/core/styles';
const items = [
  { name: 'homeSlider', icon: <HomeSliderIcon /> },
  { name: 'gallery', icon: <GalleryIcon /> },
  { name: 'giftSetting', icon: <GiftSettingIcon /> },
  { name: 'products', icon: <ProductsIcon /> },
  { name: 'productCategory', icon: <ProductCategoryIcon /> },
  { name: 'ProductsAddition', icon: <ProductsAdditionIcon /> },
  {
    name: 'ProductsAdditionCategory',
    icon: <ProductsAdditionCategoryIcon />,
  },
  { name: 'orders', icon: <OrdersIcon /> },
  { name: 'transactions', icon: <TransactionsIcon /> },
  { name: 'areas', icon: <AreasIcon /> },
  { name: 'branches', icon: <BranchesIcon /> },
  { name: 'cities', icon: <CitiesIcon /> },
  { name: 'promocodes', icon: <PromoIcon /> },
  { name: 'contactUs', icon: <ContactUsIcon /> },
  { name: 'users', icon: <UsersIcon /> },
  { name: 'usersPoints', icon: <UserPointsIcon /> },
  { name: 'usersRedeems', icon: <RedeemsIcon /> },
  { name: 'notifications', icon: <NotificationsIcon /> },
];

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
  menu: {
    // backgroundColor: '#84bf33',
    // color: '#ffffff',
  },
};

const Menu = ({ onMenuClick, translate, logout, classes }) => (
  <div style={styles.main} className={classes.menu}>
    <DashboardMenuItem onClick={onMenuClick} />
    {localStorage.getItem('subAdmin') != 1 &&
    localStorage.getItem('editorAdmin') != 1
      ? items.map((item) => (
          <div>
            <MenuItemLink
              key={item.name}
              to={`/${item.name}`}
              primaryText={translate(`${item.name}.name`, {
                smart_count: 2,
              })}
              leftIcon={item.icon}
              onClick={onMenuClick}
              className={classes.menu}
            />
          </div>
        ))
      : localStorage.getItem('editorAdmin') == 1
      ? items.map((item) => (
          <div>
            {['products', 'orders', 'cities', 'contactUs'].indexOf(
              item.name,
            ) !== -1 && (
              <MenuItemLink
                key={item.name}
                to={`/${item.name}`}
                primaryText={translate(`${item.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={item.icon}
                onClick={onMenuClick}
                className={classes.menu}
              />
            )}
          </div>
        ))
      : items.map((item) => (
          <div>
            {[
              'homeSlider',
              'areas',
              'branches',
              'cities',
              'contactUs',
              'usersPoints',
              'gallery',
              'giftSetting',
              'products',
              'productCategory',
              'ProductsAddition',
              'ProductsAdditionCategory',
              'orders',
              'Transactions',
            ].indexOf(item.name) !== -1 && (
              <MenuItemLink
                key={item.name}
                to={`/${item.name}`}
                primaryText={translate(`${item.name}.name`, {
                  smart_count: 2,
                })}
                leftIcon={item.icon}
                onClick={onMenuClick}
                className={classes.menu}
              />
            )}
          </div>
        ))}
    {localStorage.getItem('subAdmin') !== '1' &&
      localStorage.getItem('editorAdmin') !== '1' && (
        <MenuItemLink
          to='/configuration'
          primaryText={translate('pos.configuration')}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          className={classes.menu}
        />
      )}
    {localStorage.getItem('subAdmin') !== '1' &&
      localStorage.getItem('editorAdmin') !== '1' && (
        <>
          <MenuItemLink
            to='/generalNotification'
            primaryText={translate('pos.generalNotification')}
            leftIcon={<SendTwoTone />}
            onClick={onMenuClick}
            className={classes.menu}
          />
          <MenuItemLink
            to='/sms'
            primaryText={translate('pos.sms')}
            leftIcon={<SmsIcon />}
            onClick={onMenuClick}
            className={classes.menu}
          />
        </>
      )}
    {localStorage.getItem('subAdmin') !== '1' &&
      localStorage.getItem('editorAdmin') !== '1' && (
        <MenuItemLink
          to='/productsorder'
          primaryText={translate('pos.productOrder')}
          leftIcon={<Reorder />}
          onClick={onMenuClick}
          className={classes.menu}
        />
      )}

    <Responsive xsmall={logout} medium={null} />
  </div>
);

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(
    (state) => ({
      theme: state.theme,
      locale: state.i18n.locale,
    }),
    {},
  ),
  translate,
);

export default enhance(Menu);
