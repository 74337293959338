import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';

import {
  SendPOS as SendPOSAction,
  orderReject as orderRejectAction,
} from './orderActions';

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: red,
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  textColor: {
    color: '#ffffff',

    margin: 5,
  },
});

class SendPOSButton extends Component {
  handleSendPOS = () => {
    const { SendPOS, record } = this.props;
    SendPOS(record.id, record);
  };

  handleReject = () => {
    const { orderReject, record } = this.props;
    orderReject(record.id, record);
  };

  render() {
    const { record } = this.props;
    const { classes } = this.props;
    return (
      <span>
        <MuiThemeProvider theme={theme}>
          <Button
            variant='contained'
            className={classes.textColor}
            style={{ width: '150px', backgroundColor: orange[500] }}
            onClick={this.handleSendPOS}
          >
            Convert To cash and send
          </Button>
          {/* <Button
            variant="contained"
            className={classes.textColor}
            color="secondary"
            onClick={this.handleReject}
          >
            Reject
          </Button> */}
        </MuiThemeProvider>
      </span>
    );
  }
}

SendPOSButton.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
  SendPOS: PropTypes.func,
  orderReject: PropTypes.func,
};

export default connect(
  null,
  {
    SendPOS: SendPOSAction,
    orderReject: orderRejectAction,
  },
)(withStyles(styles)(SendPOSButton));
