import React from 'react';
import {
  translate,
  Create,
  Datagrid,
  Edit,
  DeleteButton,
  NumberInput,
  SimpleShowLayout,
  ShowButton,
  SelectInput,
  Filter,
  Show,
  SimpleForm,
  TextField,
  ImageField,
  required,
  RadioButtonGroupInput,
  TextInput,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import List from '../customs/List';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Email';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import DoneButton from './doneButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export const ContactUsIcon = Icon;
const RowStyle = (record, index) => ({
  backgroundColor: record.expired ? '#eaad' : 'white',
});

function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}

const listStyles = {
  image: {
    width: '20px',
  },
};

export const ContactusFilter = (props) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='q' alwaysOn />
    <SelectInput
      source='type'
      choices={[
        { id: 'contact', name: 'Contact Us ( تواصل معنا )' },
        { id: 'suggest', name: 'Suggest (أقتراح)' },
        { id: 'complain', name: 'Complain (شكوى)' },
      ]}
    />
    <DateInput source='from_date' label='From date' />
    <DateInput source='to_date' label='To date' />

    {/* <QuickFilter
      label="resources.Orders.fields.stock_lte"
      source="stock_lte"
      defaultValue={10}
    /> */}
  </Filter>
);

export const ContactUsList = withStyles(listStyles)(({ classes, ...props }) => {
  let setDisabled = localStorage.getItem('editorAdmin') != 1 ? false : true;
  return (
    <List
      perPage={25}
      {...props}
      filters={<ContactusFilter />}
      bulkActionButtons={localStorage.getItem('editorAdmin') != 1 ? '' : false}
    >
      <Datagrid rowStyle={RowStyle}>
        <TextField source='id' />
        <TextField source='name' />
        <TextField source='email' />
        <TextField source='phone' />
        <TextField source='type' />
        <TextField source='message' />
        <TextField source='order_number' />
        <ImageField
          source='photo'
          classes={{
            image: classes.image,
          }}
          title='picture.title'
        />
        <TextField source='created_at' label='Sent at' />
        <DoneButton />
        <ShowButton />
        {localStorage.getItem('editorAdmin') != 1 && <DeleteButton />}
      </Datagrid>
    </List>
  );
});

const createStyles = {
  stock: { width: '5em' },
  price: { width: '5em' },
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

export const ContactUsCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props}>
      <SimpleForm redirect='list'>
        <TextInput
          className={classes.input}
          source='amount'
          validate={required()}
        />
        <RadioButtonGroupInput
          source='used_once'
          label='Used Once?'
          defaultValue='1'
          choices={[{ id: '1', name: 'Yes' }, { id: '0', name: 'No' }]}
        />
        <DateInput
          className={classes.input}
          label='Expire at'
          source='expire_at'
          options={{ disablePast: true }}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  ),
);

const ContactUsTitle = translate(({ record, translate }) => (
  <span>
    {translate('ContactUs.name')} #{record.id}
  </span>
));

const editStyles = {
  ...createStyles,
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  image: {
    width: '100%',
    maxHeight: '100%',
  },
};

export const ContactUsEdit = withStyles(editStyles)(({ classes, ...props }) => (
  <Edit {...props} title={<ContactUsTitle />}>
    <SimpleForm redirect='list'>
      <TextInput className={classes.input} source='name' required />
      <TextInput className={classes.input} source='name_ar' />
      <NumberInput className={classes.input} source='fees' />
      <DateInput className={classes.input} source='expire_at' required />
    </SimpleForm>
  </Edit>
));

export const ContactUsShow = withStyles(editStyles)(({ classes, ...props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='name' />
      <TextField source='email' />
      <TextField source='phone' />
      <TextField source='type' />
      <TextField source='message' />
      <ImageField
        source='photo'
        classes={{
          image: classes.image,
        }}
        title='picture.title'
      />
      <TextField source='created_at' label='Sent at' />
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <img source='photo' alt='' className={classes.img} />
        </CardContent>
      </Card>
    </SimpleShowLayout>
  </Show>
));
