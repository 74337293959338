import React from 'react';
import { Redirect } from 'react-router-dom';

import {
  translate,
  Create,
  Datagrid,
  Edit,
  EditButton,
  DualListInput,
  useRefresh,
  FormTab,
  Filter,
  SingleFieldList,
  ReferenceArrayField,
  ReferenceArrayInput,
  CheckboxGroupInput,
  ChipField,
  TabbedForm,
  CardActions,
  RefreshButton,
  DisabledInput,
  FilterButton,
  NumberInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  LongTextInput,
  SelectInput,
  TextField,
  ReferenceField,
  FunctionField,
  Button,
  TextInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  required,
} from 'react-admin';
import List from '../customs/List';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Fastfood';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Poster from './Poster';
import PosterAr from './PosterAr';
import PosterMobile from './PosterMobile';
import PosterArMobile from './PosterArMobile';

export const ProductsIcon = Icon;

function booleanCheck(val) {
  if (val) {
    return <Check />;
  } else {
    return <Close />;
  }
}
function sizeCheck(val) {
  if (val.parent_item_id === 0) {
    return 'Main';
  } else {
    if (val.SizeID == 3) {
      return 'XLarge';
    }
    if (val.SizeID == 2) {
      return 'Large';
    }
    // console.log(val);
  }
  // if (val) {
  //   return <Check />;
  // } else {
  //   return <Close />;
  // }
}

const QuickFilter = translate(({ label, translate }) => (
  <Chip>{translate(label)}</Chip>
));

export const ProductsFilter = (props) => (
  <Filter {...props}>
    <TextInput label='pos.search' source='q' alwaysOn />
    <SelectInput
      source='gift'
      label='Is gift?'
      choices={[{ id: 1, name: 'Yes' }, { id: 0, name: 'No' }]}
      alwaysOn
    />
    <ReferenceInput
      source='category_id'
      reference='productCategory'
      sort={{ field: 'id', order: 'ASC' }}
    >
      <SelectInput source='name' />
    </ReferenceInput>
    <SelectInput
      source='city_id'
      defaultValue={0}
      choices={[
        { id: 0, name: 'All' },
        { id: 1, name: 'Tanta' },
        { id: 5, name: 'Mahallah' },
        { id: 2, name: 'Zagazig' },
        { id: 6, name: 'Cairo' },
      ]}
    />
  </Filter>
);

const ActionsRefresh = (props) => (
  <CardActions>
    <RefreshButton />
    <FilterButton source={ProductsFilter} />
  </CardActions>
);

const listStyles = {
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  image: {
    width: '200px',
  },
  CardActions: {
    disblay: 'none',
  },
};

export const ProductsList = withStyles(listStyles)(({ classes, ...props }) => {
  // let productId = new URLSearchParams(props.location.search).get(
  //   'parent_item_id',
  // );
  // let customeFilters = { only_parent: 1 };
  // if (productId) {
  //   customeFilters.parent_item_id = 1;
  // }
  return (
    <List
      perPage={25}
      title='Prodcuts List'
      {...props}
      filters={<ProductsFilter />}
      bulkActionButtons={false}
      // filterDefaultValues={customeFilters}
    >
      <Datagrid>
        <TextField source='name' />
        <ImageField
          source='photo'
          classes={{
            image: classes.image,
          }}
          title='picture.title'
        />
        <ReferenceArrayField
          label='Featured In'
          reference='cities'
          source='featured_on_cities'
        >
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          label='Hidden In'
          reference='cities'
          source='hide_on_cities'
        >
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField
          label='Category'
          source='product_category_id'
          reference='productCategory'
          linkType={localStorage.getItem('editorAdmin') != 1 ? 'show' : false}
        >
          <TextField source='name' />
        </ReferenceField>
        {/* <FunctionField label='Size' render={(record) => sizeCheck(record)} /> */}
        <EditButton />
        <TextField source='city_name' />
      </Datagrid>
    </List>
  );
});

const createStyles = {
  stock: { width: '5em' },
  fullWidth: { width: '100%' },
  price: { width: '5em' },
  width: { width: '5em' },
  label: { color: 'red' },
  widthFormGroup: { display: 'inline-block' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

export const ProductsCreate = withStyles(createStyles)(
  ({ classes, ...props }) => (
    <Create {...props} title='Create porduct' classes={classes}>
      <TabbedForm redirect='list'>
        <FormTab label='English Content'>
          <TextInput
            source='name'
            className={classes.fullWidth}
            label='Product name'
            validate={required()}
          />
          <LongTextInput source='description' label='Description' />
          <ImageInput
            source='image'
            label='Product photo for website'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image' title='title' />
          </ImageInput>
          <ImageInput
            source='image_mobile'
            label='Product photo for mobile'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image_mobile' title='title' />
          </ImageInput>
        </FormTab>
        <FormTab label='Arabic Content'>
          <TextInput
            source='name_ar'
            className={classes.fullWidth}
            label='Product name in arabic'
            validate={required()}
          />
          <LongTextInput
            source='description_ar'
            label='Description in arabic'
          />
          <ImageInput
            source='image_ar'
            label='Product photo for website in Arabic'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image_ar' title='title' />
          </ImageInput>
          <ImageInput
            source='image_mobile_ar'
            label='Product photo for mobile in Arabic'
            accept='image/*'
            validate={required()}
          >
            <ImageField source='image_mobile_ar' title='title' />
          </ImageInput>
        </FormTab>
        <FormTab label='Product properties'>
          <ReferenceInput
            source='product_category_id'
            label='Product category'
            reference='productCategory'
            validate={required()}
          >
            <SelectInput optionText='name' optionValue='id' />
          </ReferenceInput>
          <NumberInput source='small_size' label='Small size price' />
          <NumberInput
            source='medium_size'
            label='Medium size price'
            validate={required()}
          />
          <NumberInput source='large_size' label='Large size price' />
          <BooleanInput label='In stock' source='in_stock' />
          <BooleanInput label='Featured' source='featured' />
        </FormTab>
      </TabbedForm>
    </Create>
  ),
);

const ProductsTitle = translate(({ record, translate }) => (
  <span>Product ({record.name})</span>
));

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

function pricesCheck(val) {
  return val.productPrices.map((item) => (
    <div>
      <strong style={{ textTransform: 'capitalize' }}>{item.size}</strong> ={' '}
      {item.price}
    </div>
  ));
}
function giftCheck(val) {
  return val.productPrices.map((item) => (
    <BooleanInput label={item.size} source={item.size} />
  ));
}

export const ProductsEdit = withStyles(createStyles)(
  ({ classes, ...props }) => {
    let setDisabled = localStorage.getItem('editorAdmin') != 1 ? false : true;
    return (
      <Edit {...props} title={<ProductsTitle />}>
        {/* <TabbedForm redirect="list" asyncValidate={asyncValidate} asyncBlurFields={[ 'image' ]}> */}
        <TabbedForm redirect='list' toolbar={<CustomToolbar />}>
          <FormTab label='English Content'>
            <DisabledInput
              source='name'
              className={classes.fullWidth}
              label='Product name'
              // validate={required()}
            />
            <DisabledInput source='description' label='Description' />
            <Poster setDisabled={setDisabled} />
            <PosterMobile setDisabled={setDisabled} />
          </FormTab>
          <FormTab label='Arabic Content'>
            <DisabledInput
              source='name_ar'
              className={classes.fullWidth}
              label='Product name in arabic'
              // validate={required()}
            />
            <DisabledInput
              source='description_ar'
              label='Description in arabic'
            />
            <PosterAr setDisabled={setDisabled} />
            <PosterArMobile setDisabled={setDisabled} />
          </FormTab>
          <FormTab label='Product properties'>
            <ReferenceArrayInput
              label='Hide from Mobile App in cities :'
              reference='cities'
              disabled={setDisabled}
              filter={{ product_id: props.id }}
              style={{ width: '100%' }}
              source='hide_on_cities'
            >
              <CheckboxGroupInput>
                <ChipField source='hide_on_cities' />
              </CheckboxGroupInput>
            </ReferenceArrayInput>

            <ReferenceArrayInput
              label='Featured in cities :'
              reference='cities'
              disabled={setDisabled}
              filter={{ product_id: props.id }}
              style={{ width: '100%' }}
              source='featured_on_cities'
            >
              <CheckboxGroupInput>
                <ChipField source='hide' />
              </CheckboxGroupInput>
            </ReferenceArrayInput>

            <ReferenceArrayInput
              label='Out of stock in cities :'
              reference='cities'
              filter={{ product_id: props.id }}
              style={{ width: '100%' }}
              source='in_stock'
            >
              <CheckboxGroupInput>
                <ChipField source='in_stock' />
              </CheckboxGroupInput>
            </ReferenceArrayInput>

            <ReferenceArrayInput
              label='Gift in cities :'
              reference='cities'
              disabled={setDisabled}
              filter={{ product_id: props.id }}
              style={{ width: '100%' }}
              source='is_gift'
            >
              <CheckboxGroupInput>
                <ChipField source='is_gift' />
              </CheckboxGroupInput>
            </ReferenceArrayInput>

            {/* <ReferenceInput
          source="product_category_id"
          label="Product category"
          reference="productCategory"
          validate={required()}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput> */}
            {/* <NumberInput source="small_size" label="Price" /> */}

            <FunctionField
              label='Product Prices'
              render={(record) => pricesCheck(record)}
            />
            {/* <FunctionField
            label='Select Gift product'
            style={{
              border: 'solid #2f2fff 1px',
              padding: '13px',
              borderRadius: '3px',
            }}
            render={(record) => giftCheck(record)}
          /> */}
            {/* <NumberInput source="large_size" label="Large size price" /> */}
            {/* <BooleanInput
              label='Is Gift'
              source='is_gift'
              options={{
                disabled: setDisabled,
              }}
            />
            <BooleanInput label='In stock' source='in_stock' /> */}
            {/* <BooleanInput label='Featured' source='featured' /> */}
            {/* <BooleanInput label='Hide this product?' source='hidden' /> */}
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  },
);
