import { UPDATE } from 'react-admin';

export const ORDER_APPROVE = 'ORDER_APPROVE';
export const ORDER_APPROVE_LOADING = 'ORDER_APPROVE_LOADING';
export const ORDER_APPROVE_FAILURE = 'ORDER_APPROVE_FAILURE';
export const ORDER_APPROVE_SUCCESS = 'ORDER_APPROVE_SUCCESS';
export const SEND_POS = 'SEND_POS';

export const orderApprove = (id, data, basePath) => ({
  type: ORDER_APPROVE,
  payload: { id, data: { ...data, order_status: 'canceled' }, basePath },
  meta: {
    resource: 'orders',
    fetch: UPDATE,
    onSuccess: {
      notification: {
        body: 'Order approved!',
        level: 'info',
      },
      redirectTo: '/orders',
      basePath,
    },
    onFailure: {
      notification: {
        body: 'resources.orders.notification.approved_error',
        level: 'warning',
      },
    },
  },
});
export const CancelOrder = (id, data, basePath) => ({
  type: ORDER_APPROVE,
  payload: { id, data: { ...data, order_status: 'canceled' }, basePath },
  meta: {
    resource: 'orders',
    fetch: UPDATE,
    onSuccess: {
      notification: {
        body: 'Order Canceled!',
        level: 'info',
      },
      redirectTo: '/orders',
      basePath,
    },
    onFailure: {
      notification: {
        body: 'resources.orders.notification.approved_error',
        level: 'warning',
      },
    },
  },
});
export const SendPOS = (id, data, basePath) => ({
  type: SEND_POS,
  payload: { id, data: { ...data, send_cash_pos: 'cash' }, basePath },
  meta: {
    resource: 'orders',
    fetch: UPDATE,
    onSuccess: {
      notification: {
        body: 'Order Sent to POS!',
        level: 'info',
      },
      redirectTo: '/orders',
      basePath,
    },
    onFailure: {
      notification: {
        body: 'resources.orders.notification.approved_error',
        level: 'warning',
      },
    },
  },
});

export const ORDER_REJECT = 'ORDER_REJECT';
export const ORDER_REJECT_LOADING = 'ORDER_REJECT_LOADING';
export const ORDER_REJECT_FAILURE = 'ORDER_REJECT_FAILURE';
export const ORDER_REJECT_SUCCESS = 'ORDER_REJECT_SUCCESS';

export const orderReject = (id, data, basePath) => ({
  type: ORDER_REJECT,
  payload: { id, data: { ...data, order_status: 'cancel_rejected' }, basePath },
  meta: {
    resource: 'orders',
    fetch: UPDATE,
    onSuccess: {
      notification: {
        body: 'Order Rejected!',
        level: 'info',
      },
      redirectTo: '/orders',
      basePath,
    },
    onFailure: {
      notification: {
        body: 'resources.orders.notification.rejected_error',
        level: 'warning',
      },
    },
  },
});
